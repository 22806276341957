import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import App from './App';

import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";

const AUTH_TOKEN = 'auth-token';

  const client = new ApolloClient({
    uri: 'https://graphqland.sulcisdrone.it',
    request: (operation) => {
      const token = localStorage.getItem(AUTH_TOKEN)
      operation.setContext({
        headers: {
          authorization: token ? `Bearer ${token}` : ''
        }
      })
    }
  })

ReactDOM.render(<ApolloProvider client={client}><App /></ApolloProvider>, document.getElementById('root'));

serviceWorker.unregister();
