import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Snackbar,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { GET_CURRENTUSER, CURRENTUSER_MUTATION } from "../../../../graphql/queries";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withApollo } from "react-apollo";

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
      minimum: 6
    }
  },
  passwordConfirm: {
    equality: "password"
  }
};

const useStyles = makeStyles(() => ({
  root: {}
}));

const AccountDetails = (props) => {
  const { className, client, ...rest } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    isInitialData: false
  });
  const [detailsMessage, setDetailsMessage] = React.useState({ open: false, message: "" });

  useEffect(() => {
    if (!formState.isInitialData) {

      client
        .query({
          query: GET_CURRENTUSER
        })
        .then(res => {
          let data = res.data;

          setFormState(formState => ({
            ...formState,
            values: data.currentUser,
            isInitialData: true
          }));

        }).catch(err => console.log(err));
    }
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };


  const handleSignUp = event => {
    event.preventDefault();


    client
      .mutate({
        mutation: CURRENTUSER_MUTATION,
        variables: { id: formState.values.id, email: formState.values.email, name: formState.values.name, password: formState.values.password }
      })
      .then(res => {
        try {
          let data = res.data;
          if (data.updateUser && data.updateUser.user) {

            setFormState(formState => ({
              ...formState,
              values: { ...data.updateUser.user, ...{ password: formState.values.password, passwordConfirm: formState.values.password } },
              isInitialData: true
            }));
            setDetailsMessage({ open: true, message: "Account saved successfully" });
          }

        } catch (error) {
          console.log(error);
          setDetailsMessage({ open: true, message: "Error saving account!" });

        }

      }
      ).catch(e => {
        console.log(e);
        setDetailsMessage({ open: true, message: "Error saving account!" });
      });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setDetailsMessage({ open: false, message: "" });
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
         <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={detailsMessage.open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={detailsMessage.message}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <form
        autoComplete="off"
        onSubmit={handleSignUp}
      >
        <CardHeader
          subheader="The information can be edited"
          title="Profile"
        />
        <Divider />
            <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                     <TextField
                  className={classes.textField}
                  error={hasError('name')}
                  fullWidth
                  helperText={
                    hasError('name') ? formState.errors.name[0] : null
                  }
                  label="Name"
                  name="name"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.name || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
               <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label="Email address"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                />
              </Grid>
        
              <Grid
                item
                md={6}
                xs={12}
              >
                  <TextField
                  className={classes.textField}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.password || ''}
                  variant="outlined"
                  placeholder="*************"
                  type="password"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
              <TextField
                  className={classes.textField}
                  error={hasError('passwordConfirm')}
                  fullWidth
                  helperText={
                    hasError('passwordConfirm') ? formState.errors.passwordConfirm[0] : null
                  }
                  label="Password confirm"
                  name="passwordConfirm"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.passwordConfirm || ''}
                  variant="outlined"
                  placeholder="*************"
                  type="password"
                />
              </Grid>
            </Grid>
          </CardContent>
      

        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            disabled={!formState.isValid}
            type="submit"
          >
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default withApollo(AccountDetails);
