import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles, useTheme } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Snackbar,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { GET_MANAGER, ADDMANAGER_MUTATION } from "../../../../graphql/queries";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withApollo } from "react-apollo";
import { withRouter } from 'react-router-dom';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import MaskedInput from "react-text-mask";
import regions from './italia';

const listMiseCategories = {"1.A": "Big data e analisi dei dati","1.B": "Cloud, fog e quantum computing",
"1.C" :"Cyber security", "1.D" :"Next Production Revolution", "1.E" :"Simulazione e sistemi cyber-fisici",
 "1.F" :"Prototipazione rapida", "1.G" :"Sistemi di visualizzazione", "1.H" :"Robotica", "1.I" :"Interfaccia uomo-macchina",
  "1.L" :"Manifattura additiva", "1.M" :"Internet delle cose",
  "1.N" :"Sviluppo digitale dei processi aziendali", "1.O" :"Digital marketing","1.P" :"open innovation",
   "2.A" :"Metodi organizzativi", "2.B" :"Quotazione su mercati", "5.A": "Esportazione Cina", "5.B": "Esportazione USA", "5.C": "Esportazione Brasile"}

const  getStyles = (slug, categories, theme) => {
   let fontStyle = theme.typography.fontWeightRegular;
      categories.map( category=>{
        if(category && category.slug == slug){
          fontStyle=  theme.typography.fontWeightMedium 
         }
      });
  
  return {
    fontWeight: fontStyle
}; 
}

const schema = {
    firstName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  lastName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  fiscalCode: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  companyName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },
  affiliation: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  experience: {
    presence: false,
     type: 'integer'
  },
  phone: {
    presence: false,
    length: {
      maximum: 32
    }
  },
  categories: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  tipology: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  province: {
    presence: false,
    length: {
      maximum: 32
    }
  },
  region: {
    presence: false,
    length: {
      maximum: 32
    }
  },
  ranking: {
    presence: { allowEmpty: false, message: 'is required'},
  }

/*   urlImage: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  } */
};
function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[1-5]{1}/,
        ")",
        " ",
        "-",
        " ",
        "(",
        /[1-5]{1}/,
        ")",
        " ",
        "-",
        " ",
        "(",
        /[1-5]{1}/,
        ")"
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles(theme => ({
  root: {},
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const EditDetails = props => {
  const { className, client, ...rest } = props;

  let idItem = props.match.params.idItem;
  let originalCategories = [];
  const classes = useStyles();
  const theme = useTheme();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    isInitialData: false,
    allRegions: regions
  });
  const [detailsMessage, setDetailsMessage] = React.useState({ open: false, message: "" });

  const filterListOfCategories = (categories) => {
    return categories.map(el => {return el.slug;});
  }

  const makeListOfCategoriesToSave = (listOfSlugs) => {
 
    return listOfSlugs.map(slug => {
      let experience = 0;
      originalCategories.map(originalCategory => {
        if( originalCategory.slug == slug ){
          experience = originalCategory.experience;
        } 
      })
      return {slug: slug, experience: experience, description: listMiseCategories[slug]}}
      );
  }


  const getProvinceByRegion = (region) => {
     let provinceArray = formState.allRegions.regioni.filter( (aRegion)=>{ 
      return aRegion.nome === region; 
    });
return provinceArray[0]['province'];
  }

  useEffect(() => {
    if (idItem && !formState.isInitialData) {

      client
        .query({
          query: GET_MANAGER,
          variables: {id: idItem},
          errorPolicy: 'all'
        })
        .then(res => {
          if(res.errors){
            setDetailsMessage({ open: true, message: "Error opening manager!" });
            return;
          }
          let data = res.data;
          originalCategories = data.manager.categories;
          if(data.manager && data.manager.categories && data.manager.categories.length && typeof data.manager.categories[0] === 'object'){
            data.manager.categories = filterListOfCategories(data.manager.categories);
          }

          const errors = validate(data.manager, schema);

          setFormState(formState => ({
            ...formState,
            values: data.manager,
            isInitialData: true,
            errors: errors || {}
          }));

        }).catch(err => console.log(err));
    } else{
      const errors = validate(formState.values, schema);

      setFormState(formState => ({
        ...formState,
        isValid: errors ? false : true,
        errors: errors || {}
      }));
    }

  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    // check if region is changed
    // if( event.target.name === 'ranking' ){
    //   event.target.value =  parseInt(event.target.value.replace(/[^0-9]/g,''));
    // }

    //  if ( event.target.name === 'categories' ){
    //   event.target.value = {slug: event.target.value, description: listMiseCategories[event.target.value]};
    //  }

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };


  const handleSignUp = event => {
    event.preventDefault();

let ranking = parseInt(formState.values.ranking.toString().replace(/[^0-9]/g,''));
// retrieve experiences from original categories
let listOfCategories = makeListOfCategoriesToSave(formState.values.categories);
    client
      .mutate({
        mutation: ADDMANAGER_MUTATION,
        variables: { id: formState.values._id, 
          firstName: formState.values.firstName,
          lastName: formState.values.lastName || '',
          email: formState.values.email || '',
          fiscalCode: formState.values.fiscalCode || '',
          companyName: formState.values.companyName || '',
          affiliation: formState.values.affiliation || '',
          experience: parseInt(formState.values.experience) || 0,
          phone: formState.values.phone || '',
          categories: listOfCategories || '',
          tipology: formState.values.tipology || '',
          province: formState.values.province || '',
          region: formState.values.region || '',
          ranking: ranking|| '',
          linkedin: formState.values.linkedin || '',
          urlImage: formState.values.urlImage || ''      
           }
      })
      .then(res => {
        try {
          let data = res.data;
          if (data.addManager && data.addManager._id) {

            originalCategories = data.addManager.categories;
            if(data.addManager && data.addManager.categories && data.addManager.categories.length){
              data.addManager.categories = filterListOfCategories(data.addManager.categories);
            }
            setFormState(formState => ({
              ...formState,
              values: data.addManager,
              isInitialData: false
            }));
            setDetailsMessage({ open: true, message: "Manager saved successfully" });
          }

        } catch (error) {
          console.log(error);
          setDetailsMessage({ open: true, message: "Error saving manager! " + error.message });

        }

      }
      ).catch(e => {
        console.log(e);
        setDetailsMessage({ open: true, message: "Error saving manager! " + e.message });
      });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setDetailsMessage({ open: false, message: "" });
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
         <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={detailsMessage.open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={detailsMessage.message}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <form
        autoComplete="off"
        onSubmit={handleSignUp}
      >
        <CardHeader
          subheader="The information can be edited"
          title="Profile"
        />
        <Divider />
            <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                     <TextField
                  className={classes.textField}
                  error={hasError('firstName')}
                  fullWidth
                  helperText={
                    hasError('firstName') ? formState.errors.firstName[0] : null
                  }
                  label="First Name"
                  name="firstName"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.firstName || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
               <TextField
                  className={classes.textField}
                  error={hasError('lastName')}
                  fullWidth
                  helperText={
                    hasError('lastName') ? formState.errors.lastName[0] : null
                  }
                  label="Last Name"
                  name="lastName"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.lastName || ''}
                  variant="outlined"
                />
              </Grid>
        
              <Grid
                item
                md={6}
                xs={12}
              >
  <TextField
                  className={classes.textField}
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label="Email address"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >  
              <TextField
              className={classes.textField}
              error={hasError('fiscalCode')}
              fullWidth
              helperText={
                hasError('fiscalCode') ? formState.errors.fiscalCode[0] : null
              }
              label="Fiscal Code"
              name="fiscalCode"
              onChange={handleChange}
              type="text"
              value={formState.values.fiscalCode || ''}
              variant="outlined"
            />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
<TextField
                  className={classes.textField}
                  error={hasError('companyName')}
                  fullWidth
                  helperText={
                    hasError('companyName') ? formState.errors.companyName[0] : null
                  }
                  label="Company Name"
                  name="companyName"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.companyName || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
              className={classes.textField}
              error={hasError('affiliation')}
              fullWidth
              helperText={
                hasError('affiliation') ? formState.errors.affiliation[0] : null
              }
              label="Affiliation"
              name="affiliation"
              onChange={handleChange}
              type="text"
              value={formState.values.affiliation || ''}
              variant="outlined"
            />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
  <TextField
                  className={classes.textField}
                  error={hasError('experience')}
                  fullWidth
                  helperText={
                    hasError('experience') ? formState.errors.experience[0] : null
                  }
                  label="Experience"
                  name="experience"
                  onChange={handleChange}
                  type="number"
                  value={parseInt(formState.values.experience) || 0}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                  <TextField
                  className={classes.textField}
                  error={hasError('phone')}
                  fullWidth
                  helperText={
                    hasError('phone') ? formState.errors.phone[0] : null
                  }
                  label="Phone"
                  name="phone"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.phone || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >

<Select
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          multiple
          value={formState.values.categories || []}
          onChange={handleChange}
          input={<Input name="categories" id="select-multiple-chip" />}
          renderValue={selected => (
            <div className={classes.chips}>
              {formState.values.categories && formState.values.categories.map(slug => (
              <Chip key={slug} label={listMiseCategories[slug]} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {Object.keys(listMiseCategories).map(slug => (
            <MenuItem key={slug} value={slug} style={getStyles(slug, formState.values.categories || [], theme)}>
              {listMiseCategories[slug]}
            </MenuItem>
          ))}
        </Select>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                  <TextField
                  className={classes.textField}
                  error={hasError('tipology')}
                  fullWidth
                  helperText={
                    hasError('tipology') ? formState.errors.tipology[0] : null
                  }
                  label="Tipology"
                  name="tipology"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.tipology || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
<TextField
                  className={classes.textField}
                  error={hasError('region')}
                  fullWidth
                  select
                  helperText={
                    hasError('region') ? formState.errors.region[0] : null
                  }
                  label="Region"
                  name="region"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.region || ''}
                  variant="outlined"
                >
          {regions.regioni.map(option => (
            <MenuItem key={option.nome} value={option.nome}>
              {option.nome}
            </MenuItem>
          ))}
        </TextField>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
       <TextField
                  className={classes.textField}
                  error={hasError('province')}
                  fullWidth
                  select
                  helperText={
                    hasError('province') ? formState.errors.province[0] : null
                  }
                  label="Province"
                  name="province"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.province || ''}
                  variant="outlined"
                  >
                  {formState.values.region && getProvinceByRegion(formState.values.region).map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
       <TextField
                  className={classes.textField}
                  error={hasError('ranking')}
                  fullWidth
                  helperText={
                    hasError('ranking') ? formState.errors.ranking[0] : null
                  }
                  label="Ranking"
                  name="ranking"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.ranking || ''}
                  variant="outlined"
                  InputProps={{
                    inputComponent: TextMaskCustom
                  }}
                />
                
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                      <TextField
                  className={classes.textField}
                  error={hasError('linkedin')}
                  fullWidth
                  helperText={
                    hasError('linkedin') ? formState.errors.linkedin[0] : null
                  }
                  label="Linkedin"
                  name="linkedin"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.linkedin || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >

              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
              </Grid>
            </Grid>
          </CardContent>
      

        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            disabled={!formState.isValid}
            type="submit"
          >
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};



export default withRouter(withApollo(EditDetails));
