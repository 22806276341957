import React, { useState, useEffect, Fragment} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

import { SearchInput } from 'components';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'table-row',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  rowSearch: {
    display: 'table-row'
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1),
   minWidth: 'auto'
  },
  searchInput: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),

  }
}));

const ProductsToolbar = props => {
  const { className, handleSearchChange, handleFilterChange, filterValue, ...rest } = props;

  const classes = useStyles();
  const [miseColor, setMiseColor] = useState("default");
  const [affiliatiColor, setAffiliatiColor] = useState("default");

  useEffect(() => {
    console.log("aggiornato " + filterValue)  }, [filterValue]);

    let listMiseCategories = {"1.A": "Big data e analisi dei dati","1.B": "Cloud, fog e quantum computing",
 "1.C" :"Cyber security", "1.D" :"Next Production Revolution", "1.E" :"Simulazione e sistemi cyber-fisici",
  "1.F" :"Prototipazione rapida", "1.G" :"Sistemi di visualizzazione", "1.H" :"Robotica", "1.I" :"Interfaccia uomo-macchina",
   "1.L" :"Manifattura additiva", "1.M" :"Internet delle cose",
   "1.N" :"Sviluppo digitale dei processi aziendali", "1.O" :"Digital marketing","1.P" :"open innovation",
    "2.A" :"Metodi organizzativi", "2.B" :"Quotazione su mercati", "5.A": "Esportazione Cina", "5.B": "Esportazione USA", "5.C": "Esportazione Brasile"}

  let filter = {};

  const setTipologyChipColor = (value) => {
    if (value === "mise") {
      //setMiseColor(miseColor == "primary" ? "default" : "primary");
      setMiseColor("primary");
      setAffiliatiColor("default");
    }

    if (value === "affiliati") {
      //setAffiliatiColor(affiliatiColor == "primary" ? "default" : "primary");
      setAffiliatiColor("primary");
      setMiseColor("default");
    }
  }

  const handleClick = (value, ev) => {
    setTipologyChipColor(value);
    filter["tipology"] = value;
    handleFilterChange(filter);
  };

  const handleDelete = (filterType, aFilterValue) => {

    if(filterType == "categories"){
      filter["categories"] = null;
      handleFilterChange(filter);
    }
    if(filterType == "tipology"){
      aFilterValue == "mise" ? setMiseColor("default") : setAffiliatiColor("default");
      if(filterValue["tipology"] == aFilterValue){
        filter["tipology"] = null;
        handleFilterChange(filter);
      }

    }
    console.info('You clicked the delete icon.');
  };

  const searchChanged = (e) => {
    console.log("search changed: " + e.target.value);
    handleSearchChange(e.target.value);
  };

   const type = props.type;
   const placeholders = {
     companies: "Search a company",
     products: "Search a product",
     tourism: "Search a tourism professional",
     importer: "Search an importer",
     managers: "Cerca un manager per nome, regione/provincia o categoria"
   }
  return (
    <div
    {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button disabled={type === 'companies' ? true : false} href="companies" className={classes.exportButton}  color="primary">Companies</Button>
         {/*<Button disabled={type === 'drones' ? true : false} href="drones" className={classes.exportButton}  color="primary">Drones</Button> */}
        <Button disabled={type === 'products' ? true : false} href="products" className={classes.exportButton} color="primary">
          Products
        </Button>
        <Button disabled={type === 'importers' ? true : false} href="importers" className={classes.exportButton} color="primary">
          Importers
        </Button>
        <Button disabled={type === 'tourism' ? true : false} href="tourism" className={classes.exportButton} color="primary">
          Tourism
        </Button>
        <Button disabled={type === 'managers' ? true : false} href="managers" className={classes.exportButton} color="primary">
          Managers
        </Button>
      </div>
      <div className={classes.rowSearch}>
        <SearchInput
          className={classes.searchInput}
          placeholder={placeholders[type]}
          onChange={searchChanged}
        />
        {type === 'managers' && <Fragment><Chip color={miseColor} avatar={<Avatar>M</Avatar>} clickable label="Mise" onClick={ (event) => handleClick("mise", event) } onDelete={ () => handleDelete("tipology", "mise") }  />
        <Chip  color={affiliatiColor} avatar={<Avatar>A</Avatar>} label="Affiliati" onClick={ (event) => handleClick("affiliati", event) }  onDelete={ () => handleDelete("tipology", "affiliati") }/>
  {( filterValue.categories && filterValue.categories.length ) &&<Chip  color="primary" avatar={<Avatar>C</Avatar>} label={listMiseCategories[filterValue.categories]}  onDelete={ () => handleDelete("categories", filterValue.categories) }  />}</Fragment>}
      </div>
    </div>
  );
};

ProductsToolbar.propTypes = {
  className: PropTypes.string
};

export default ProductsToolbar;
