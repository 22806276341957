import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Grid } from '@material-ui/core';
import CssBaseline from "@material-ui/core/CssBaseline";
import { ProductsToolbar, ItemCard, CompanyCard, ImporterCard, PilotCard, ManagerCard} from './components';
import { GET_PRODUCTS, GET_COMPANIES, GET_IMPORTERS, GET_PILOTS, GET_MANAGERS } from "../../graphql/queries";
import { Query } from "react-apollo";
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from "material-ui-flat-pagination";
const theme = createMuiTheme();

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  currentStylePage:{
    fontSize: "3em"
  }
}));

const paginationClasses = {
  sizeLarge:"2em",
  text:"2em",
  sizeLargeCurrent: "currentStylePage"
}
const ItemList = (props) => {
  const classes = useStyles();

  const [offset, setOffset] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState({});
  const [total, setTotal] = useState(5);
  let maxElements = 12;
  let type = props.match.params.type;

  const handleClick = (offset) => {
    setOffset(offset);
  }

  const handleSearchChange = (value) => {
    if(value.length > 2 || value.length === 0){
      console.log("arrived a new search: " + value);
      setSearchValue(value);
      setOffset(0);
    }
  };

  const handleFilterChange = (value) => {
    if(value && Object.keys(value).length > 0 ){
      console.log("arrived a new filter: " + value);
      setFilterValue({...filterValue, ...value });
      setOffset(0);
    }
  };

  return (
    <div className={classes.root}>
      <ProductsToolbar type={type} filterValue={filterValue} handleSearchChange={handleSearchChange} handleFilterChange={handleFilterChange} />
      <div className={classes.content}>
        <Grid
          container
          spacing={3}
        >     
       {(() =>{ if(type === "products") return (
        <Query query={GET_PRODUCTS} variables={ {from:offset, many: maxElements, search: searchValue }}>
        {({ loading, error, data }) => {
          
          if (loading) return <CircularProgress/>;
          if (error) return <p>Error :(</p>;
            data.products[0].allCount && data.products[0].allCount.length && setTotal( parseInt(data.products[0].allCount[0].count));
          if( data.products[0].allCount )   return (
                data.products[0].allData.map(product => (
                  <Grid
                  item
                  key={product.id}
                  lg={3}
                  md={6}
                  xs={12}
                >
                  <ItemCard key={product.id} product={product} />
                </Grid>
                ))
            );
          return null;
          }}
        </Query>)
        })()}

       {(() =>{ if(type === "companies") return (
        <Query query={GET_COMPANIES} variables={ {from:offset, many: maxElements, search: searchValue }}>
        {({ loading, error, data }) => {
          if (loading) return <CircularProgress/>;
          if (error) return <p>Error :(</p>;
            data.companies[0].allCount && data.companies[0].allCount.length && setTotal( parseInt(data.companies[0].allCount[0].count));
          if( data.companies[0].allCount )   return (
                data.companies[0].allData.map(company => (
                  <Grid
                  item
                  key={company.id}
                  lg={3}
                  md={6}
                  xs={12}
                >
                  <CompanyCard company={company} />
                </Grid>
                ))
            );
          }}
        </Query>)
        })()}  

{(() =>{ if(type === "importers") return (
        <Query query={GET_IMPORTERS} variables={ {from:offset, many: maxElements, search: searchValue }}>
        {({ loading, error, data }) => {
          if (loading) return <CircularProgress/>;
          if (error) return <p>Error :(</p>;
            data.importers[0].allCount && data.importers[0].allCount.length && setTotal( parseInt(data.importers[0].allCount[0].count));
          if( data.importers[0].allCount )   return (
                data.importers[0].allData.map(importer => (
                  <Grid
                  item
                  key={importer.id}
                  lg={3}
                  md={6}
                  xs={12}
                >
                  <ImporterCard importer={importer} />
                </Grid>
                ))
            );
          }}
        </Query>)
        })()}  

               {(() =>{ if(type === "tourism") return (
        <Query query={GET_PILOTS} variables={ {from:offset, many: maxElements, search: searchValue }}>
        {({ loading, error, data }) => {
          if (loading) return <CircularProgress/>;
          if (error) return <p>Error :(</p>;
            data.pilots[0].allCount && data.pilots[0].allCount.length && setTotal( parseInt(data.pilots[0].allCount[0].count));
          if( data.pilots[0].allCount )   return (
                data.pilots[0].allData.map(pilot => (
                  <Grid
                  item
                  key={pilot.id}
                  lg={3}
                  md={6}
                  xs={12}
                >
                  <PilotCard pilot={pilot} />
                </Grid>
                ))
            );
          }}
        </Query>)
        })()}


{(() =>{ if(type === "managers") return (
        <Query query={GET_MANAGERS} variables={ {from:offset, many: maxElements, search: searchValue,
          filter: filterValue }}>
        {({ loading, error, data }) => {
          if (loading) return <CircularProgress/>;
          if (error) return <p>Error :(</p>;
            data.managers[0].allCount && data.managers[0].allCount.length && setTotal( parseInt(data.managers[0].allCount[0].count));
          if( data.managers[0].allCount )   return (
                data.managers[0].allData.map(manager => (
                  <Grid
                  item
                  key={manager.fiscalCode}
                  lg={3}
                  md={6}
                  xs={12}
                >
                  <ManagerCard manager={manager} handleFilterChange={handleFilterChange} />
                </Grid>
                ))
            );
          }}
        </Query>)
        })()}

        </Grid>
      </div>
      <div className={classes.pagination}>
        {}
        <MuiThemeProvider theme={theme}>
        <CssBaseline />
      <Pagination size={"large"}
      currentPageColor={"primary"}
          limit={12}
          offset={offset}
          total={total}
          onClick={(e, offset) => handleClick(offset)}
          classes={paginationClasses}
        />
              </MuiThemeProvider>
      </div>
    </div>
  );
};

export default ItemList;
