import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer, Typography } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import ImageIcon from '@material-ui/icons/Image';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsIcon from '@material-ui/icons/Settings';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import FlightIcon from '@material-ui/icons/Flight';
import ShopIcon from '@material-ui/icons/Shop';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import BookIcon from '@material-ui/icons/Book';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ViewListIcon from '@material-ui/icons/ViewList';
import LanguageIcon from '@material-ui/icons/Language';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import DnsIcon from '@material-ui/icons/Dns';
import EmojiFoodBeverageIcon from '@material-ui/icons/EmojiFoodBeverage';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import FacebookIcon from '@material-ui/icons/Facebook';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';
import InstagramIcon from '@material-ui/icons/Instagram';
import DuoIcon from '@material-ui/icons/Duo';
import SchoolIcon from '@material-ui/icons/School';
import DraftsIcon from '@material-ui/icons/Drafts';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import StoreIcon from '@material-ui/icons/Store';
import EcoIcon from '@material-ui/icons/Eco';

import { Profile, SidebarNav, UpgradePlan } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: 'Azes',
      href: 'http://www.gbroker.it/mk3/login.php',
      icon: <PeopleIcon />
    },
    {
      title: 'Azit',
      href: 'https://gbroker.it/admin2/login.php',
      icon: <PeopleIcon />
    },
    {
      title: 'Ibblog',
      href: 'http://gbroker.it/ibblog/wp-login.php',
      icon: <BookIcon />
    },
    {
      title: 'Crm Licensing',
      href: 'https://www.sulcisdrone.it/sdm_downloads/crm-licensing/',
      icon: <ListAltIcon />
    },
    {
      title: 'Cataloghi export/turismo',
      href: 'https://drive.google.com/drive/folders/0B_d6zrmwaCkkT2xzUzVPeTl1a0E',
      icon: <ViewListIcon />
    },
    {
      title: 'Contenuti web',
      href: 'http://gbroker.it/ibblog/wp-login.php',
      icon: <LanguageIcon />
    },
    {
      title: 'Risorse marketing',
      href: 'http://gbroker.it/ibblog/wp-login.php',
      icon: <ShopIcon />
    },
    {
      title: 'FlyingNose',
      href: 'http://www.sulcisdrone.it/progetto-flyingnose/',
      icon: <FlightTakeoffIcon />
    },
    {
      title: 'LiquidWeb',
      href: 'https://www.liquidweb.com',
      icon: <DnsIcon />
    },
    {
      title: 'Manuale Operativo',
      href: 'https://www.sulcisdrone.it/manuale-operativo/',
      icon: <LocalLibraryIcon />
    },
    {
      title: 'Sulcisdrone',
      href: 'https://www.sulcisdrone.it',
      icon: <LanguageIcon />
    },
    {
      title: 'Italybureau.it',
      href: 'https://italybureau.it',
      icon: <LanguageIcon />
    },
    {
      title: 'Italia Longevity',
      href: 'https://italialongevity.it',
      icon: <EmojiFoodBeverageIcon />
    },
    {
      title: 'Noleggio Manager / Consulenti',
      href: 'https://and.sulcisdrone.it/items/managers',
      icon: <MonetizationOnIcon />
    },
    {
      title: 'Noleggio Importers',
      href: 'https://and.sulcisdrone.it/items/importers',
      icon: <MonetizationOnIcon />
    },
    {
      title: 'Noleggio Aziende',
      href: 'https://and.sulcisdrone.it/items/companies',
      icon: <MonetizationOnIcon />
    },
    {
      title: 'Drone Share',
      href: 'https://droneshare.club',
      icon: <FlightIcon />
    },
    {
      title: 'MercatiEsteri',
      href: 'https://www.mercatiesteri.eu',
      icon: <ShoppingBasketIcon />
    },
    {
      title: 'Facebook Affiliati',
      href: 'https://www.facebook.com/groups/ItalyBureau',
      icon: <FacebookIcon />
    },
    {
      title: 'Canale issuu1',
      href: 'https://issuu.com/campana1976',
      icon: <CallMissedOutgoingIcon />
    },
    {
      title: 'Canale issuu2',
      href: 'https://issuu.com/italybureau',
      icon: <CallMissedOutgoingIcon />
    },
    {
      title: 'Instagram Longevity',
      href: 'https://www.instagram.com/italialongevity/',
      icon: <InstagramIcon />
    },
    {
      title: 'Instagram Droneshare',
      href: 'https://www.instagram.com/droneshare.club/',
      icon: <InstagramIcon />
    },
    {
      title: 'Alibaba',
      href: 'https://www.alibaba.com/',
      icon: <LanguageIcon />
    },
    {
      title: 'ZoomUs',
      href: 'https://us02web.zoom.us/j/3595544358',
      icon: <DuoIcon />
    },
    {
      title: 'Nuau 1',
      href: 'https://fadconfesercenti.sulcisdrone.it/',
      icon: <SchoolIcon />
    },
    {
      title: 'Nuau 2',
      href: 'https://fad.italybureau.it/',
      icon: <SchoolIcon />
    },
    {
      title: 'MailUp',
      href: 'https://www.mailup.it/',
      icon: <DraftsIcon />
    },
    {
      title: 'CRM Mailup',
      href: 'https://www.mailup.it/',
      icon: <DraftsIcon />
    },
    {
      title: 'MailChimp',
      href: 'https://mailchimp.com/',
      icon: <DraftsIcon />
    },
    {
      title: 'Channel Facebook',
      href: 'https://www.facebook.com/ItalyBureauAlliance',
      icon: <FacebookIcon />
    },  {
      title: 'Channel Drone',
      href: 'https://www.facebook.com/droneshare.club',
      icon: <FacebookIcon />
    },
    {
      title: 'Channel Linkedin',
      href: 'https://www.linkedin.com/company/5365921/admin/',
      icon: <LinkedInIcon />
    },
    {
      title: 'Channel Agrelma',
      href: 'https://www.agrelma.com/',
      icon: <EcoIcon />
    },
    {
      title: 'Channel Aprire Franchising',
      href: 'https://www.aprireinfranchising.it/italybureau-alliance-licencing',
      icon: <StoreIcon />
    },
    {
      title: 'Channel Info Franchising',
      href: 'https://www.infofranchising.it/italybureau-alliance-franchising-consulenza-digitale/',
      icon: <StoreIcon />
    },
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Typography>
          Tools
        </Typography>
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
