import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import {  brown } from '@material-ui/core/colors';
import LanguageIcon from '@material-ui/icons/Language';
import PropTypes from 'prop-types';
import BusinessIcon from '@material-ui/icons/Business';
import ReactCountryFlag from "react-country-flag";

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 385,
    minHeight: 440
  },
  title:{
    fontSize:'0.9rem',
    fontWeight: 800
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: 'transparent',
  },
  buttonTag: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: "0.6rem"
  },
  actions:{
    display: 'table'
  }
}));


const PilotCard = props => {
  const { className, pilot, ...rest } = props;

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const defaultImage = "https://atasouthport.com/wp-content/uploads/2017/04/default-image-150x150.jpg";
  const tagRootUrl = "https://droneshare.club/en/pilots/?refine_ls=&cat_project_skill[]=";

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.card}>
      <CardHeader
       classes={{
        title: classes.title, // class name, e.g. `classes-nesting-root-x`
      }}
        avatar={
          <Avatar aria-label="recipe"  className={classes.avatar}>
                {pilot.nation && <ReactCountryFlag countryCode={pilot.nation} svg  aria-label={pilot.nation}    style={{
                    fontSize: '2em',
                    lineHeight: '2em',
                }}/>}   

          </Avatar>
        }
        action={
          <span></span>
        }
        title={pilot.name}
        subheader={pilot.address}
      />
      <CardMedia
        className={classes.media}
        image={pilot.urlImage && pilot.urlImage.length > 0 ? pilot.urlImage : defaultImage} 
        title={pilot.name}
      />
       <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">{pilot.description.slice(0,140)}...
        </Typography>
      </CardContent>
      <CardActions disableSpacing className={classes.actions}>
        <IconButton aria-label="add to favorites"  target="_blank" href={pilot.uri}>
          <LinkIcon />
        </IconButton>
        {pilot.skills && pilot.skills[0] && pilot.skills.map((skill, index) => ( 
         <Button key={skill.slug + " " + pilot.id}  className={classes.buttonTag} variant="contained"  size="small" target="_blank" href={tagRootUrl + skill.slug} color="secondary">{skill.name}</Button> ))}
        {pilot.description && pilot.description.length > 140 &&  <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>}
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>
          {pilot.description}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
PilotCard.propTypes = {
  className: PropTypes.string,
  pilot: PropTypes.object.isRequired
};

export default PilotCard;
