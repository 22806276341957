import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { red, brown } from '@material-ui/core/colors';
import LinkIcon from '@material-ui/icons/Link';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import FastfoodIcon from '@material-ui/icons/Fastfood';



const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 385,
    minHeight: 440
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: brown[200],
  },
  buttonTag: {
    marginLeft: theme.spacing(1)
  }
}));


const ItemCard = props => {
  const { className, product, ...rest } = props;

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const defaultImage = "https://atasouthport.com/wp-content/uploads/2017/04/default-image-150x150.jpg";
  const categoryRootUrl = "https://catalog.italialongevity.it/product-category/";
  const tagRootUrl = "https://catalog.italialongevity.it/product-tag/";
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.card}>
      <CardHeader
      key={"header_" + product.id}
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
             <FastfoodIcon />
          </Avatar>
        }
        action={
          <span></span>
        }
        title={product.name}
        subheader={product.categories.map((cat, index) => (cat.name!== 'Uncategorized'? <Button key={cat.slug + "_" + product.id} size="small" target="_blank" href={categoryRootUrl + cat.slug} color="primary">{cat.name}</Button> : ''))}
      />
      <CardMedia
      key={"media_" + product.id}
        className={classes.media}
        image={product.urlImage && product.urlImage.length > 4? product.urlImage : defaultImage} 
        title={product.name}
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">{product.shortDescription.slice(0,140)}...
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites"  target="_blank" href={product.permalink}>
          <LinkIcon />
        </IconButton>
        {product.tags.map((tag, index) => ( 
         <Button key={ tag.slug + "_" + product.id} className={classes.buttonTag} variant="contained"  size="small" target="_blank" href={tagRootUrl + tag.slug} color="secondary">{tag.name}</Button> ))}
        {product.shortDescription && product.shortDescription.length > 140 &&  <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>}
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>
          {product.shortDescription}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
ItemCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired
};

export default ItemCard;
