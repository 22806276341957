import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout, MinimalAuth as MinimalAuth } from './layouts';

import {
  Dashboard as DashboardView,
  ProductList as ProductListView,
  UserList as UserListView,
  Typography as TypographyView,
  Icons as IconsView,
  Account as AccountView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  Home as HomeView,
  ItemList as ItemListView,
  Tools as ToolsView,
  EditItem as EditItemView,
  NotFound as NotFoundView
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/home"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        component={ProductListView}
        exact
        layout={MinimalAuth}
        path="/products"
      />
      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MinimalAuth}
        path="/account"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalAuth}
        path="/not-found"
      />
      <RouteWithLayout
        component={HomeView}
        exact
        layout={MinimalAuth}
        path="/home"
      />
            <RouteWithLayout
        component={ItemListView}
        exact
        layout={MinimalAuth}
        path="/items/:type"
      />     
      <RouteWithLayout
      component={ToolsView}
      exact
      layout={MinimalAuth}
      path="/tools"
    />
          <RouteWithLayout
      component={EditItemView}
      exact
      layout={MinimalAuth}
      path="/edititem/:idItem"
    />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
