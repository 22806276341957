import React from 'react';

// Material components
import { SvgIcon } from '@material-ui/core';

export default function Visit(props) {
  return (
    <SvgIcon {...props}>
<g>
	<g>
		<path d="M453.815,288.113c-0.075-0.886-0.269-1.758-0.575-2.593v0l-0.252-0.682c-1.528-5.687-3.577-11.22-6.121-16.531
			l-50.529-136.473c-4.503-13.712-13.265-25.63-25.011-34.017l-1.8-12.77c-0.085-0.601-0.224-1.193-0.416-1.769
			c-6.776-20.16-25.71-33.709-46.978-33.617c-26.649,0-48.826,21.858-49.436,48.725v0.44l0.426,19.757
			c-4.6,7.839-7.559,16.529-8.7,25.546h-72.528c-1.142-9.006-4.097-17.688-8.688-25.52l0.423-19.412c0-0.148,0-0.236,0-0.384
			c-0.611-26.867-22.789-48.695-49.437-48.695c-21.271-0.086-40.204,13.468-46.978,33.632c-0.186,0.56-0.322,1.136-0.407,1.72
			l-1.81,12.346c-11.743,8.388-20.503,20.303-25.006,34.013L9.465,268.306c-2.544,5.311-4.593,10.845-6.121,16.532l-0.252,0.681
			c-0.307,0.837-0.5,1.712-0.575,2.6C-9.535,340.133,22.86,392.068,74.874,404.12c52.014,12.052,103.949-20.344,116.001-72.357
			c1.659-7.159,2.496-14.484,2.495-21.833c0-1.05-0.023-2.1-0.056-3.138c0.039-0.385,0.058-0.773,0.056-1.16l-0.213-33.5h70.026
			l-0.223,35.5c0,0.168,0.015,0.363,0.022,0.529c-0.01,0.579-0.022,1.172-0.022,1.753c0.017,53.389,43.311,96.655,96.7,96.638
			c53.389-0.017,96.655-43.311,96.638-96.7C456.295,302.535,455.462,295.242,453.815,288.113z M134.192,70.148
			c15.274,0,28.081,12.129,29.345,27.386c-16.372-11.472-36.958-15.17-56.3-10.115C112.029,76.85,122.587,70.085,134.192,70.148z
			 M78.812,138.604c0.045-0.122,0.088-0.245,0.128-0.368c8.26-25.182,35.371-38.9,60.553-30.64
			c10.212,3.35,19.004,10.031,24.967,18.972c0.188,0.331,0.396,0.651,0.621,0.959c4.784,7.534,7.321,16.276,7.314,25.2l0.623,97.942
			c-30.287-39.091-85.218-48.97-127.227-22.88L78.812,138.604z M165.561,343.649c-12.871,26.289-39.588,42.955-68.859,42.952v-0.004
			c-42.356-0.013-76.681-34.36-76.668-76.716c0.002-6.403,0.806-12.781,2.393-18.984l5.43-14.666
			c18.617-38.026,64.535-53.76,102.561-35.143C168.444,259.705,184.178,305.624,165.561,343.649z M263.299,252.13h-70.27l-0.1-15
			h70.47L263.299,252.13z M263.529,217.13h-70.723l-0.333-53h71.385L263.529,217.13z M322.137,69.661
			c11.818-0.065,22.525,6.954,27.179,17.817c-19.414-5.145-40.109-1.458-56.551,10.075
			C293.779,82.059,306.693,69.661,322.137,69.661z M283.934,152.655c0-26.166,21.506-47.454,47.941-47.454
			c8.514-0.008,16.877,2.258,24.222,6.564c0.489,0.364,1.01,0.683,1.557,0.953c9.335,5.962,16.31,14.979,19.735,25.513
			c0.04,0.127,0.083,0.25,0.128,0.372l0,0l33.021,89.185c-42.004-26.088-96.929-16.216-127.219,22.867L283.934,152.655z
			 M378.611,384.203c-6.203,1.587-12.581,2.391-18.984,2.393l0.002,0.002c-42.343,0.013-76.679-34.303-76.692-76.646
			c-0.013-42.343,34.303-76.679,76.646-76.692c29.285-0.009,56.017,16.666,68.889,42.97l5.43,14.666
			C444.4,331.93,419.646,373.705,378.611,384.203z"/>
	</g>
</g>
<g>
	<g>
		<path d="M96.701,241.429c-37.831,0-68.5,30.668-68.5,68.5c0,37.831,30.669,68.5,68.5,68.5c37.832,0,68.5-30.669,68.5-68.5
			C165.16,272.115,134.516,241.471,96.701,241.429z M96.701,358.43c-26.786,0-48.5-21.714-48.5-48.5c0-26.786,21.714-48.5,48.5-48.5
			c26.786,0,48.5,21.714,48.5,48.5C145.172,336.703,123.475,358.4,96.701,358.43z"/>
	</g>
</g>
<g>
	<g>
		<path d="M358.411,241.429c-37.832,0-68.5,30.668-68.5,68.5c0,37.831,30.668,68.5,68.5,68.5c37.831,0,68.5-30.669,68.5-68.5
			C426.869,272.115,396.225,241.471,358.411,241.429z M358.411,358.43c-26.786,0-48.5-21.714-48.5-48.5
			c0-26.786,21.714-48.5,48.5-48.5c26.786,0,48.5,21.714,48.5,48.5C406.882,336.703,385.184,358.401,358.411,358.43z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>    </SvgIcon>
  );
}
