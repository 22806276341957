import gql from "graphql-tag";

const GET_PRODUCTS = gql`
  query($from: Int, $many: Int, $search: String) {
    products(from:$from, many: $many, search: $search){
  allData{
    name
    related
    images
    tags
    categories
    price
    id
    description
    shortDescription
    permalink
    urlImage
        }
      allCount
    }
  }
`;

const GET_COMPANIES = gql`
query($from: Int, $many: Int, $search: String) {
  companies(from:$from, many: $many, search: $search){
      allData{
        id
        gbId
        name
        address
        city
        web
        email
        piva
        contactName
        legalRepresentative
        profile
        products
        keywords
        catalog
        urlImage
        broker{ name }
            }
      allCount
    }
  }
`;

const GET_IMPORTERS = gql`
query($from: Int, $many: Int, $search: String) {
  importers(from:$from, many: $many, search: $search){
      allData{
        gid,
        id,
      name,
      country,
      address,
      zip,
      city,
      province,
      phone,
      fax,
      email,
      personalEmail,
      contact,
      role,
      web,
      linkedin,
      facebook,
      skype,
      fonte,
      note
      urlImage
            }
      allCount
    }
  }
`;

const GET_PILOTS = gql`
query($from: Int, $many: Int, $search: String) {
  pilots(from:$from, many: $many, search: $search){
      allData{
     
        id,
        name,
        firstname,
        lastname,
        rating,
        skills,
        description,
        address,
        nation,
        web,
        urlImage,
        uri
            }
      allCount
    }
  }
`;

const GET_MANAGER = gql`
  query($id: ID) {
    manager(id:$id){
      _id,
      firstName,
      lastName,
      email,
      fiscalCode,
      companyName,
      affiliation,
      experience,
      phone,
      categories,
      tipology,
      province,
      region,
      ranking,
      linkedin,
      urlImage
    }
  }
`;

const GET_MANAGERS = gql`
query($from: Int, $many: Int, $search: String, $filter: ManagerFilterArg) {
  managers(from:$from, many: $many, search: $search, filter: $filter){
      allData{
        _id,
        firstName,
  lastName,
  email,
  fiscalCode,
  companyName,
  affiliation,
  experience,
  phone,
  categories,
  tipology,
  province,
  region,
  ranking,
  linkedin,
  urlImage
            }
      allCount
    }
  }
`;

const SIGNUP_MUTATION = gql`
  mutation SignupMutation($email: String!, $password: String!, $name: String!) {
    signup(email: $email, password: $password, name: $name) {
      token
      user
    }
  }
`

const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user
    }
  }
`
const GET_CURRENTUSER = gql`
query currentUser {
  currentUser {
    id
    name
    email
    votes
    profileImage
    role
    }
  }
`;

const CURRENTUSER_MUTATION = gql`
mutation updateUser($id: ID!, $email: String!, $password: String!, $name: String!, $profileImage: String) {
  updateUser(id: $id, email: $email, password: $password, name: $name, profileImage: $profileImage) {
  user
  }
}
`;

const ADDMANAGER_MUTATION = gql`
mutation addManager(   $id: String!, 
  $firstName: String!,
  $lastName: String!,
  $email: String!,
  $fiscalCode: String!,
  $companyName: String!,
  $affiliation: String!,
  $experience: Int!,
  $phone: String!,
  $categories: [JSON]!,
  $tipology: String!,
  $province: String!,
  $region: String!,
  $ranking: Int!,
  $linkedin: String!,
  $urlImage: String!,
  ) {
  addManager(
    id: $id, 
    firstName: $firstName,
    lastName: $lastName,
    email: $email,
    fiscalCode: $fiscalCode,
    companyName: $companyName,
    affiliation: $affiliation,
    experience: $experience,
    phone: $phone,
    categories: $categories,
    tipology: $tipology,
    province: $province,
    region: $region,
    ranking: $ranking,
    linkedin: $linkedin,
    urlImage: $urlImage
    ){
      _id, 
      firstName,
      lastName,
      email,
      fiscalCode,
      companyName,
      affiliation,
      experience,
      phone,
      categories,
      tipology,
      province,
      region,
      ranking,
      linkedin,
      urlImage
  }
}
`
export { GET_PRODUCTS, GET_COMPANIES, GET_IMPORTERS, GET_PILOTS, GET_MANAGER, GET_MANAGERS, SIGNUP_MUTATION, LOGIN_MUTATION, GET_CURRENTUSER, CURRENTUSER_MUTATION, ADDMANAGER_MUTATION };
