import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {  brown } from '@material-ui/core/colors';
import LanguageIcon from '@material-ui/icons/Language';
import PropTypes from 'prop-types';
import BusinessIcon from '@material-ui/icons/Business';
import ReactCountryFlag from "react-country-flag";

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 385,
    minHeight: 440
  },
  title:{
    fontSize:'0.9rem',
    fontWeight: 800
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: 'transparent',
  },
  buttonTag: {
    marginLeft: theme.spacing(1),
    fontSize: "0.6rem"
  }
}));


const ImporterCard = props => {
  const { className, importer, ...rest } = props;

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const defaultImage = "https://atasouthport.com/wp-content/uploads/2017/04/default-image-150x150.jpg";
  const categoryRootUrl = "https://catalog.italialongevity.it/company-category/";
  const tagRootUrl = "https://catalog.italialongevity.it/company-tag/";
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.card}>
      <CardHeader
       classes={{
        title: classes.title, // class name, e.g. `classes-nesting-root-x`
      }}
        avatar={
          <Avatar aria-label="recipe"  className={classes.avatar}>
                   <ReactCountryFlag countryCode={importer.country} svg  aria-label={importer.country}    style={{
                    fontSize: '2em',
                    lineHeight: '2em',
                }}/>

          </Avatar>
        }
        action={
          <span></span>
        }
        title={importer.name}
        subheader={importer.city}
      />
      <CardMedia
        className={classes.media}
        image={importer.urlImage && importer.urlImage.length > 0 ? importer.urlImage : defaultImage} 
        title={importer.name}
      />
      <CardContent>
      <Typography variant="body2" color="textSecondary" component="p">
        {importer.province}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">{importer.address && importer.address} {importer.zip && importer.zip}
        </Typography>
        <Typography variant="h6" color="textSecondary" component="p">
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
      <Button size="small" color="primary"   target="_blank" href={"https://" + importer.web}>
      <LanguageIcon />{importer.web}
        </Button>
      </CardActions>
    </Card>
  );
}
ImporterCard.propTypes = {
  className: PropTypes.string,
  importer: PropTypes.object.isRequired
};

export default ImporterCard;
