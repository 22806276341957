import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { brown } from '@material-ui/core/colors';
import PropTypes from 'prop-types';

import PermIdentityIcon from '@material-ui/icons/PermIdentity';


const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 385,
    minHeight: 440
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: brown[200],
  },
  buttonTag: {
    marginLeft: theme.spacing(1),
    fontSize: "0.6rem"
  }
}));


const BrokerCard = props => {
  const { className, broker, ...rest } = props;

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);


  return (
    <Typography variant="body2" color="textSecondary" component="p">
     <PermIdentityIcon/>{broker}</Typography>

  );
}
BrokerCard.propTypes = {
  className: PropTypes.string,
  broker: PropTypes.object.isRequired
};

export default BrokerCard;
