import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import InputIcon from '@material-ui/icons/Input';
import BuildIcon from '@material-ui/icons/Build';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, history,  ...rest } = props;

  const classes = useStyles();

  const [notifications] = useState([]);

  const AUTH_TOKEN = 'auth-token';
  const authToken =  localStorage.getItem(AUTH_TOKEN);

  const handleSignOut = event => {
    event.preventDefault();
    localStorage.removeItem(AUTH_TOKEN)
    window.location.href = "/sign-in"
  };

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src="/images/logos/logo-tazebao.png"
          />
        </RouterLink>
        <div className={classes.flexGrow} />
          {authToken && (
          <IconButton  onClick={handleSignOut}
            className={classes.signOutButton}
            color="inherit"
          >
            <InputIcon />
          </IconButton>
          )}
          {authToken && (<IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <BuildIcon />
          </IconButton>)}
          {authToken && (<IconButton color="inherit" href="/account">
              <AccountCircleIcon />
          </IconButton>)}
      </Toolbar>
      {!authToken && ( window.location.href = "/sign-in")}
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
