import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { red, brown } from '@material-ui/core/colors';
import LanguageIcon from '@material-ui/icons/Language';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import BusinessIcon from '@material-ui/icons/Business';
import { BrokerCard} from '../../components';

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 385,
    minHeight: 440
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: brown[200],
  },
  buttonTag: {
    marginLeft: theme.spacing(1),
    fontSize: "0.6rem"
  }
}));


const CompanyCard = props => {
  const { className, company, ...rest } = props;

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const defaultImage = "https://atasouthport.com/wp-content/uploads/2017/04/default-image-150x150.jpg";
  const categoryRootUrl = "https://catalog.italialongevity.it/company-category/";
  const tagRootUrl = "https://catalog.italialongevity.it/company-tag/";
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
             <BusinessIcon />
          </Avatar>
        }
        action={
          <span></span>
        }
        title={company.name}
        subheader={company.products && company.products.split(",").map((cat, index) => (cat== 'Uncategorized'? <Button size="small" color="primary">{cat.trim()}</Button> : ''))}
      />
      <CardMedia
        className={classes.media}
        image={company.urlImage && company.urlImage.length > 0 ? company.urlImage : defaultImage} 
        title={company.name}
      />
      <CardContent>
      <Typography variant="h6" color="textSecondary" component="p">
        {company.city}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">{company.profile && company.profile.slice(0,140)}...
        </Typography>
        <Typography variant="h6" color="textSecondary">
        {company.broker && company.broker.name!=="Nessuno"? 
        <BrokerCard broker={company.broker.name }/>
        : <p></p>
      }
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites"  target="_blank" href={company.web}>
          <LanguageIcon />
        </IconButton>
        {company.keywords && company.keywords.split(",").map((tag, index) => ( 
        tag !== " " && tag.length>0 && <Button key={tag.trim() + "_" + company.id} className={classes.buttonTag} variant="contained"  size="small"  color="primary" disabled >{tag.trim()}</Button> ))}
       {company.profile && company.profile.length > 140 &&  <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>}
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>
          {company.profile}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
CompanyCard.propTypes = {
  className: PropTypes.string,
  company: PropTypes.object.isRequired
};

export default CompanyCard;
