import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import {  brown } from '@material-ui/core/colors';
import LanguageIcon from '@material-ui/icons/Language';
import PropTypes from 'prop-types';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import ReactCountryFlag from "react-country-flag";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const copy = require('clipboard-copy');

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 385,
    minHeight: 440
  },
  title:{
    fontSize:'0.9rem',
    fontWeight: 800
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: 'transparent',
  },
  buttonTag: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: "0.6rem"
  },
  actions:{
    display: 'table'
  }
}));


const ManagerCard = props => {
  const { className, manager, handleFilterChange,  ...rest } = props;

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const defaultImage = "https://res.cloudinary.com/inventu/image/upload/c_scale,w_175/v1579789146/head-659651_640_hq8kma.png";

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  let filter = {};

  const handleCategoryClick = (slug) => {
    filter["categories"] = [slug];
    handleFilterChange(filter);
  };

  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  return (
    <Card className={classes.card}>
      <CardHeader
       classes={{
        title: classes.title, // class name, e.g. `classes-nesting-root-x`
      }}
        avatar={
          <Avatar aria-label="recipe"  className={classes.avatar}>
          </Avatar>
        }
        action={
          <span></span>
        }
        title={manager.firstName + " " + manager.lastName}
        subheader={manager.companyName}
      />
      <CardMedia
        className={classes.media}
        image={manager.urlImage && manager.urlImage.length > 0 ? manager.urlImage : defaultImage} 
        title={manager.lastName}
      />
       <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">{manager.affiliation}
        </Typography>
      </CardContent>
      <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={tooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Email copied!"
              >
           
                <IconButton aria-label="Copy email"  onClick={() => {copy(manager.email);handleTooltipOpen(); }}>
          <FileCopyIcon />
        </IconButton>
              </Tooltip>
          </ClickAwayListener>
        <IconButton aria-label="Edit manager"  target="_blank" href={"/editItem/" + manager._id}>
          <EditTwoToneIcon />
        </IconButton>
      <CardActions disableSpacing className={classes.actions}>
        {manager.categories && manager.categories[0] && manager.categories.map((category, index) => ( 
         <Button  onClick={()=> handleCategoryClick(category.slug)} key={category.slug + " " + manager.fiscalCode}  className={classes.buttonTag} variant="contained"  size="small">{category.description}</Button> ))}
         </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
ManagerCard.propTypes = {
  className: PropTypes.string,
  manager: PropTypes.object.isRequired
};

export default ManagerCard;
